<template>
  <div class="text-light">
    <!-- <pre class="text-warning">{{ getAllAccounts }}</pre> -->

    <div v-if="1 == 0" class="ms-3">
      <base-button type="button" @click="importFromJson()"
        >Import JSON</base-button
      >
      <!-- <h4 class="text-warning">{{ importCurrentRow }} / {{ importCounter }}</h4> -->
    </div>

    <base-dialog
      :show="!!error"
      title="An error occurred!"
      @close="handleError"
    >
      <p>{{ error }}</p>
    </base-dialog>

    <div class="text-warning mt-2 mb-3">
      <h4>Symbols</h4>
    </div>

    <base-button
      class="mt-2"
      @click="addNewRec()"
      data-bs-toggle="modal"
      data-bs-target="#modaltransaction"
      >Add <font-awesome-icon icon="fa-solid fa-square-plus"
    /></base-button>

    <base-card>
      <li>
        <base-text-search v-model="searchText"></base-text-search>
      </li>
    </base-card>

    <!-- DIALOG EDIT -->
    <div
      class="modal fade"
      id="modaltransaction"
      tabindex="-1"
      aria-labelledby="vLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header bg-warning">
            <h5 class="modal-title fs-5 text-dark" id="modaltransactionLabel">
              {{ modalTransactionCaption }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div class="modal-body bg-dark text-light">
            <form @submit.prevent="">
              <!-- radio -->

              <div class="row mb-3">
                <!-- <label
                    class="col-sm-3 form-check-label fw-normal text-warning"
                    for="radioactive"
                    >Active</label
                  > -->

                <label
                  for="radioactive"
                  class="col-sm-3 form-check-label text-end"
                >
                  <span>&nbsp;&nbsp;&nbsp;Active</span>
                </label>

                <div class="col-sm-8 ms-3 form-check form-switch">
                  <input
                    @click="toggleRadio"
                    class="form-check-input"
                    type="checkbox"
                    id="radioactive"
                    v-model="activeModal"
                  />
                </div>

                <!-- <label
                    class="col-sm-4 form-check-label text-end fw-normal text-warning"
                    for="radioTransaction"
                    >Active</label
                  >
                  <div class="col-sm-8 form-check form-switch">
                    <input
                      @click="toggleRadio()"
                      class="form-check-input"
                      type="checkbox"
                      id="radioTransaction"
                      v-model="active"
                    />
                  </div> -->
              </div>

              <!-- Symbol -->
              <div class="row mb-1 mt-4">
                <label for="symbol" class="col-sm-3 col-form-label text-end"
                  >Symbol
                </label>

                <div class="col-sm-9">
                  <input
                    class="form-control-sm pull-left"
                    type="text"
                    id="symbol"
                    v-model.trim="symbol"
                  />
                </div>
              </div>

              <!-- Description -->
              <div class="row mb-1 mt-1">
                <label
                  for="description"
                  class="col-sm-3 col-form-label text-end"
                  >Description
                </label>

                <div class="col-sm-9">
                  <input
                    class="form-control form-control-sm"
                    type="text"
                    id="description"
                    v-model.trim="description"
                  />
                </div>
              </div>

              <!-- Type -->
              <div class="row mb-1 mt-1">
                <label for="type" class="col-sm-3 col-form-label text-end"
                  >Type
                </label>

                <div class="col-sm-9">
                  <input
                    class="form-control-sm pull-left"
                    type="text"
                    id="description"
                    v-model.trim="type"
                  />
                </div>
              </div>

              <!-- Currency -->
              <div class="row mb-1 mt-1">
                <label for="currency" class="col-sm-3 col-form-label text-end"
                  >Currency
                </label>

                <div class="col-sm-9">
                  <input
                    class="form-control-sm pull-left"
                    type="text"
                    id="currency"
                    v-model.trim="currency"
                  />
                </div>
              </div>

              <!-- Order -->
              <div class="row mb-3 mt-1">
                <label for="order" class="col-sm-3 col-form-label text-end"
                  >Order
                </label>

                <div class="col-sm-9">
                  <input
                    class="form-control-sm pull-left"
                    type="number"
                    id="order"
                    v-model.trim="order"
                  />
                </div>
              </div>

              <div class="float-end mb-2">
                <base-button class="text-end" data-bs-dismiss="modal"
                  >Cancel</base-button
                >

                <base-button
                  class="text-end"
                  data-bs-dismiss="modal"
                  data-bs-target="#modaltransaction"
                  :disabled="disableSave()"
                  @click="saveAccounts()"
                  >Save <font-awesome-icon icon="fa-floppy-disk"
                /></base-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- END DIALOG -->

    <!-- <section>
        <base-card>
          <p>AccountList.vue</p>
          <base-button @click="saveAccounts">Accounts IMPORT</base-button>
        </base-card>
      </section> -->

    <base-card class="bg-dark text-light">
      <section>
        <base-card v-if="isLoading">
          <div v-if="isLoading">
            <base-spinner></base-spinner>
          </div>
        </base-card>

        <base-card class="bg-dark text-light">
          <div>Number of records: {{ getAllAccounts.length }}</div>
        </base-card>
      </section>

      <table class="table table-striped table-dark">
        <thead>
          <tr class="text-warning">
            <th>Edit</th>
            <th>
              <a class="jr-link" :href="null" @click="sortData('active')"
                >Active</a
              >
            </th>

            <th>
              <a class="jr-link" :href="null" @click="sortData('guid')">Guid</a>
            </th>

            <th>
              <a class="jr-link" :href="null" @click="sortData('symbol')"
                >Symbol</a
              >
            </th>

            <th>
              <a class="jr-link" :href="null" @click="sortData('description')"
                >Description</a
              >
            </th>

            <th>
              <a class="jr-link" :href="null" @click="sortData('type')">Type</a>
            </th>

            <th>
              <a class="jr-link" :href="null" @click="sortData('currency')"
                >Currency</a
              >
            </th>

            <th>
              <a class="jr-link" :href="null" @click="sortData('order')"
                >Order</a
              >
            </th>
          </tr>
        </thead>

        <tbody>
          <symbol-item
            @select-id="selectId"
            @updateradio="updateRadio"
            v-for="item in getAllAccounts"
            :key="item.guid"
            :index="item.guid"
            :guid="item.guid"
            :symbol="item.symbol"
            :currency="item.currency"
            :description="item.description"
            :type="item.type"
            :selectedguid="getSelectedGuid"
            :order="item.order"
            :show-active="'1'"
            v-model:active="item.active"
          ></symbol-item>
        </tbody>
      </table>
    </base-card>
  </div>
</template>

<script setup>
import { useInvestSymbolStore } from '../../stores/InvestSymbolStore';
import SymbolItem from '../../components/invest/SymbolItem.vue';
import { ref, computed, reactive, onMounted } from 'vue';
import {
  loadFromFirebasePinia,
  saveToFirebaseWithOptions,
} from '../../components/utils/firebaseUtils';
import appUtils from '../../components/utils/appUtils';
import getSymbolData from './symbolData.js';

const pageStore = useInvestSymbolStore();

//const isLoading = ref(false);
//const selectedItem = toRef({});
const error = ref(null);
const description = ref('');
const symbol = ref('');
const currency = ref('USD');
const type = ref('Share');
const order = ref(0);
const activeModal = ref(true);
const selectedguid = ref('');
const modalTransactionCaption = ref('Add symbol');
const searchText = ref(null);
const dataSorting = ref('');
const dataSortingOrder = ref('asc');
const allAccounts = reactive([]);

const isLoading = ref(false);

onMounted(() => {
  console.log('mounted');
  allAccounts.value = pageStore.symbolsWithGuid;
  console.log(allAccounts.value);
  sortData('order');
});

async function importFromJson() {
  const items = getSymbolData.getData();

  //importCounter.value = items.length;

  for (var i = 0; i < items.length; i++) {
    let optionObj = { url: `invest_symbols/{uid}/{newKey}` };
    let saveObj = {};

    saveObj.description = items[i].description;
    saveObj.active = true; // items[i].active;
    saveObj.order = 0; //items[i].order;
    saveObj.symbol = items[i].symbol;
    saveObj.type = items[i].type;
    saveObj.currency = items[i].currency;

    await saveNewUpdateCommon(saveObj, optionObj);
  }
}

const getAllAccounts = computed(function () {
  let tmp = pageStore.symbolsWithGuid;
  if (searchText.value) tmp = fastFilterAcc(tmp, searchText.value);
  return tmp;
});

const getSelectedGuid = computed(function () {
  if (selectedguid.value) {
    return selectedguid.value;
  } else {
    return '';
  }
});

function updateRadio(par) {
  let elem = allAccounts.value.find((x) => x.guid === par[2]);
  elem.active = par[0];
}

function updateAccount() {
  loadFromFirebasePinia({}, 'invest_symbols', null, selectedguid.value).then(
    (snapshot) => {
      //we get from firebase original object
      let saveObj = appUtils.getObjectCopy(snapshot.val());

      let optionObj = { url: `invest_symbols/{uid}/${selectedguid.value}` };
      saveNewUpdateCommon(saveObj, optionObj);
    }
  );
}

function saveNewUpdateCommon(saveObj, optionObj) {
  saveObj.description = description.value;
  saveObj.active = activeModal.value;
  saveObj.order = order.value;
  saveObj.symbol = symbol.value;
  saveObj.type = type.value;

  saveToFirebaseWithOptions(
    optionObj,
    saveObj,
    pageStore.loadInvestSymbolsFirebase
  ).then(() => {
    allAccounts.value = pageStore.symbolsWithGuid;
    sortData(dataSorting.value, true);
  });
}

function saveNewAccount() {
  let optionObj = { url: `invest_symbols/{uid}/{newKey}` };
  saveNewUpdateCommon({}, optionObj);
}

function saveAccounts() {
  if (selectedguid.value) {
    updateAccount();
  } else {
    saveNewAccount();
  }
}

function disableSave() {
  return false;
}

function addNewRec() {
  modalTransactionCaption.value = 'Add symbol';
  selectedguid.value = null;
  description.value = '';
  symbol.value = '';
  type.value = 'Share';
  order.value = 0;
  currency.value = 'USD';
}

function sortData(field, reload = false) {
  console.log(
    `sortData | field=${field} | dataSorting=${dataSorting.value} | dataSortingOrder=${dataSortingOrder.value}`
  );

  if (reload) {
    if (field == dataSorting.value) {
      if (dataSortingOrder.value == 'asc') {
        dataSortingOrder.value = 'desc';
      } else {
        dataSortingOrder.value = 'asc';
      }
      appUtils.setSortingParams(
        field,
        dataSorting,
        dataSortingOrder,
        pageStore
      );
    }
  } else {
    appUtils.setSortingParams(field, dataSorting, dataSortingOrder, pageStore);
  }
}

function selectId(parObj) {
  console.log(parObj);
  selectedguid.value = parObj.guid;
  description.value = parObj.description;
  symbol.value = parObj.symbol;
  order.value = parObj.order;
  type.value = parObj.type;
  activeModal.value = parObj.active;
  modalTransactionCaption.value = 'Edit Symbol [' + parObj.guid + ']';
}

function handleError() {
  error.value = null;
}

function toggleRadio(eventArgs) {
  console.log(eventArgs.target.checked);
  activeModal.value = eventArgs.target.checked;
}

function fastFilterAcc(tmp, searchText) {
  const fieldArr = ['description', 'symbol', 'type', 'currency'];

  if (searchText.length <= 1) {
    return tmp.filter(() => {
      return true;
    });
  } else {
    return tmp.filter(
      appUtils.fastFilterGeneral.bind(this, searchText, fieldArr)
    );
  }
}
</script>
